import React, { useEffect, useState, useRef } from "react";
import Dropdown from 'react-dropdown'
import DatePicker from 'react-date-picker';
import { createUUID } from "./react-flow-pedigree/utils";
import Select from 'react-select';

const statusOptions = [
  {"label":"Pending", "value":"pending"},
  {"label":"Completed", "value":"completed"},
  {"label" : "Canceled", "value":"canceled"}

]

const emptyGeneDetail = {
  rkey: '',
  id: '',
  gene: '',
  umls_id: '',
  umls_name: '',
  result: '',
  variant: '',
  status: '',
  type: '',
  panel_name: '',
  uuid: ''
};

interface LabProps {
  onSelectGenePanel: (panelName: string,panelId: number ,genes: any) => void;
  onPanelStatusUpdated: (e: any) => void;
  onPanelNotesUpdated: (e: any) => void;
  onPanelDateUpdated: (e: any) => void;
  onEditCustomPanel: () => void;
  allLabs: any;
  allPanels: any;
  activePanel: any
  panel_date: any;
  panel_notes: string;
  panel_status: any;
  panel_id: any;

}



// React functional component with TypeScript
const CustomSelectLab: React.FC<LabProps> = ({onEditCustomPanel, onSelectGenePanel, onPanelStatusUpdated, onPanelDateUpdated, onPanelNotesUpdated ,allLabs, allPanels, activePanel, panel_id, panel_date, panel_notes, panel_status }) =>{

    const [labList, setLabList] = useState([{'value':'', 'label':''}]);
    const [labs, setLab] = useState({'value':'', 'label':''});
    
    const [labTests, setLabTests] = useState([{'value':' ', 'label':' '}]);
    const [selectedPanel, setSelectedPanel] = useState({ 'value': '', 'label': '' });

    const [genePanelList, setGenePanelList] = useState<{ [key: string]: any }>({});
    
    const [status, setStatus] = useState({'value':'', 'label':''});
   
    const [panelNotes, setPanelNotes] = useState('');
   
    const [panelDate, setDate] = useState<Date | null>(null);
    let labPanelMap = new Map()
    const timerRef = useRef<any>(null);


  useEffect(() => {
    let selectedLab: any ={};
    const genePanelList: Record<string, any[]> = {};
    const finalLabList = allLabs.map((lab: any) => ({
      label: lab.lab,
      value: lab.id,
      
    }));    
    Object.entries(allPanels).forEach(([panelId, data]) => {
      const { genes, labs, panel_id, panel_name } = data as { genes: any[], labs: number, panel_id: number, panel_name: string};

      // Update labPanelMap
      if (labPanelMap.has(labs)) {
        labPanelMap.get(labs)!.push({ panel_id, panel_name });
      } else {
        labPanelMap.set(labs, [{ panel_id, panel_name }]);
      }
  
      // Create panelGenes array
      const panelGenes = genes.map(({ gene: name, umls_id }) => ({
        ...emptyGeneDetail,
        panelId,
        uuid: createUUID(),
        gene: name,
        umls_id,
        umls_name: name,
      }));
      genePanelList[panelId] = panelGenes;
    });

    if (panel_id) {
      setSelectedPanel({
        value: panel_id,
        label: activePanel,
      });
  
      labPanelMap.forEach((value, key) => {
        value.forEach((v: any) => {
          if (v.panel_id === panel_id) {            
            selectedLab = allLabs.find((lab: any) => {
              return lab.id === key;
            });
      
            if (selectedLab) {
              setLab({
                value: key,
                label: selectedLab.lab,
              });
            }
          }
        });
      });
      
    }
    if (selectedLab) {
      const panelsForLab = labPanelMap.get(selectedLab.id);      
      if (panelsForLab) {  
        const values = panelsForLab.map((panel: any) => ({
          value: panel.panel_id,
          label: panel.panel_name,
        }));
    
        setLabTests(values);
      } else {
        setLabTests([]);
      }
    }
    setLabList(finalLabList);
    setGenePanelList(genePanelList);

    if(panel_status){
      setStatus(statusOptions.find((status) => status.value === panel_status) || { value: '', label: '' });
    }

    if(panel_date){
      let timezone = new Date(panel_date + "T12:00:00");
      setDate(timezone)
    }

    if(panel_notes){
      setPanelNotes(panel_notes)
    }

  }, [allLabs, panel_id, activePanel]);


  const updateDate = (e:any) => {
    if(e == null) {
      onPanelDateUpdated(null)
      return ''
    }  {
      let fullYear = e.getFullYear()
      let month = e.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }

      let day = e.getDate();
      if (day < 10) {
        day = `0${day}`;
      }

      const string_dob = fullYear + "-" + month + "-" + day;
      onPanelDateUpdated(string_dob)
      setDate(e)
    }
  }


  const updateLab = (e:any) => {
    let labPanels: any = []
    for(let panel in allPanels){
      if (allPanels.hasOwnProperty(panel)) {

        let panelName = allPanels[panel].panel_name
        let panelId = allPanels[panel].panel_id
        let labs = allPanels[panel].labs
        if (labs === e.value){
          labPanels.push({
            'label': panelName,
            'value': panelId
          })
        }
      }
    }
    if(e.value === 'edit_custom'){
      onEditCustomPanel()
      return
    }
    setLabTests(labPanels)
    setLab({
      'value': e.value,
      'label': e.label
    })
  }

  const updateSelectedPanel = (e:any) => {
    let value = e.value
    let label = e.label
    onSelectGenePanel(e.label, value, genePanelList[value])
    setSelectedPanel({
      'value': value,
      'label': label
    })
  }

  const updatePanelStatus = (e:any) => {
    let value = e.value
    let label = e.label
    onPanelStatusUpdated(value)
    setStatus({
      'value': value,
      'label': label
    })
  }

  const updateNotes = (e:any) => {
    setPanelNotes(e.target.value)
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer for 500ms
    timerRef.current = setTimeout(() => {
      onPanelNotesUpdated(e.target.value); // Update notes 500ms after user stops typing
    }, 500);
  }

  let labOptions = [
    { label: ' ', value: '' },
    ...labList,
    { label: 'Add/Edit Custom...', value: 'edit_custom' }
  ];

  let panelOptions = [
    {value: ' ', label: '' },
    ...labTests
  ]
  
  // Ensure value is an object from labOptions
  const selectedLab = labOptions.find(option => option.label === labs.label) || null;
  const panelSelection = panelOptions.find(option => option.label === selectedPanel.label) ||null;

  return (
    <div className="row">
        <div className="col-md-2">
            <div className="form-group">
              <label>Lab</label>
                <div >
                  <Select
                    className='react-select-container'
                    classNamePrefix="react-select"
                    options={labOptions}
                    onChange={updateLab}
                    value={selectedLab}
                    placeholder="&nbsp;"
                    menuShouldScrollIntoView={false}
                  />
                </div>
              </div>
            </div>


        <div className="col-md-5">
            <div className="form-group">
            <label>Test</label>
              <div>
                <Select
                  className='react-select-container'
                  classNamePrefix="react-select"
                  options={panelOptions}
                  value={panelSelection}
                  onChange={updateSelectedPanel}
                  placeholder="&nbsp;"
                  menuShouldScrollIntoView={false}
                />
              </div>
            </div>
        </div>


        <div className="col-md-2">
          <div className="form-group">
            <label>Date</label>
            <div className="">
                <DatePicker
                  name="dob"
                  value={panelDate}
                  className="form-control"
                  dayPlaceholder={"dd"}
                  monthPlaceholder={"mm"}
                  yearPlaceholder={"yyyy"}
                  format="MM/dd/yyyy" 
                  clearIcon={null}
                  disableCalendar={true}
                  onChange={updateDate}
                />
              </div>
            </div>
          </div>          


        <div className="col-md-2">
            <div className="form-group">
            <label>Status</label>

              <div className="">
                <Dropdown
                  options={[{'value': ' ', 'label': ' '},
                    ...statusOptions
                  ]}
                  value={status.label || 'Select Status'}
                  onChange={updatePanelStatus}
                  placeholder="&nbsp;"
                />
              </div>
            </div>
          </div> 
        <div className="col-md-11">
            <div className="form-group">
            <label>Notes</label>
            <div className="custom-select">
              <input
                  onChange={updateNotes}
                  value={panelNotes}
                  name='panelNotes'
                  type="text" className="form-control" 
              />
            </div>
          </div>
        </div>    
      </div>      
    );
};

export default CustomSelectLab;

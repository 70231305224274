export default {
    patient: {
      rkey: 'k862u9fk',
      hash_key: 1585065108800,
      id: null,
      uuid: null,
      family_id: null,
      patient_id: '',
      first_name: '',
      last_name: '',
      middle_name: '',
      gender: '',
      gender_identity: null,
      twin_set: null,
      twin_type: null,
      adopted_status: null,
      partner_count: 0,
      ethnicity: null,
      birthDate: '',
      email: '',
      dial_code: ' ',
      phone_number: '',
      jewish: null,
      father_adopted: false,
      mother_adopted: false,
      pronoun: '',
      other_pronoun: '',
      other: '',
      father: {
        id: null,
        first_name: '',
        last_name: '',
        twin_set: null,
        twin_type: null,
        cause_of_death: null,
        is_dead: false,
        age_of_death: null,
        ancestry: null,
        gender: 'm',
        rkey: 'k862u9fl'
      },
      mother: {
        id: null,
        first_name: '',
        last_name: '',
        twin_set: null,
        twin_type: null,
        cause_of_death: null,
        is_dead: false,
        age_of_death: null,
        ancestry: null,
        gender: 'f',
        rkey: 'k862u9fm'
      },
      paternal_grandfather: {
        id: null,
        first_name: '',
        last_name: '',
        twin_set: null,
        twin_type: null,
        cause_of_death: null,
        is_dead: false,
        age_of_death: null,
        gender: 'm',
        rkey: 'k862u9fn'
      },
      paternal_grandmother: {
        id: null,
        first_name: '',
        last_name: '',
        twin_set: null,
        twin_type: null,
        cause_of_death: null,
        is_dead: false,
        age_of_death: null,
        gender: 'f',
        rkey: 'k862u9fo'
      },
      maternal_grandfather: {
        id: null,
        first_name: '',
        last_name: '',
        twin_set: null,
        twin_type: null,
        cause_of_death: null,
        is_dead: false,
        age_of_death: null,
        gender: 'm',
        rkey: 'k862u9fp'
      },
      maternal_grandmother: {
        id: null,
        first_name: '',
        last_name: '',
        twin_set: null,
        twin_type: null,
        cause_of_death: null,
        is_dead: false,
        age_of_death: null,
        gender: 'f',
        rkey: 'k862u9fq'
      },
      cause_of_death: null,
      is_dead: false,
      age_of_death: null
    },
    profile: {},
    partners: {},
    sons_daughters: {},
    siblings: [],
    uncles_aunts: {
      paternal: [],
      maternal: []
    },
    history: {},
    history_diseases: {},
    history_genes: {},
    documents: {},
    risk_settings: {
      boadicea: false,
      claus: false,
      gail: false,
      tyrer_cuzick: false,
      competing_mortality: false,
      premm: false,
      brcapro: false,
      mmrpro: false,
      pancpro: false,
      melapro: false,
      qrisk3: false
    },
    risk_factors: {
      risk_factor_id: null,
      generalRiskFactors: {
        heightFeet: '',
        heightInches: '',
        heightMeters: '',
        heightCentimeters: '',
        heightUnitSystem: 'feet',
        weightPounds: '',
        weightKilograms: '',
        weightUnitSystem: 'pounds',
        totalInches: ''
      },
      reproductiveRiskFactors: {
        ageFirstMenstruation: '',
        ageFirstBirth: '',
        postMenopausal: '',
        ageAtMenopause: '',
        takenHRT: '',
        howManyYearsOnHRT: '',
        typeOfHRT: '',
        yearsSinceLastTakenHRT: '',
        yearsOfIntendedUseOfHRT: ''
      },
      proceduresRiskFactors: {
        breastDensityMethod: '',
        biRadsDensity: '',
        percentageDensity: '',
        hadBreastBiopsy: false,
        amountOfBreastBiopsies: '',
        breastBiopsyBenign: false,
        breastBiopsyHyperplasia: false,
        breastBiopsyAtypicalHyperplasia: false,
        breastBiopsyLCIS: false,
        breastBiopsyUnknown: false
      },
      surgeriesRiskFactors: {
        hadHysterectomy: false,
        ageAtHysterectomy: '',
        hadMastectomy: false,
        ageAtMastectomy: '',
        typeOfMastectomy: '',
        hadOophorectomy: false,
        ageAtOophorectomy: '',
        typeOfOophorectomy: '',
        hadSalpingectomy: false,
        ageAtSalpingectomy: '',
        hadTubalLigation: false,
        ageAtTubalLigation: ''
      }
    },
    risk_results: {
      boadicea: {
        breast: {fiveYear: 'N/A', lifetime: 'N/A'},
        ovarian: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        population_cancer_risks: [],
        mutation_probabilities: [
          {'no mutation': {decimal: '', percent: 'N/A'}},
          {BRCA1: {decimal: '', percent: 'N/A'}},
          {BRCA2: {decimal: '', percent: 'N/A'}},
          {PALB2: {decimal: '', percent: 'N/A'}},
          {CHEK2: {decimal: '', percent: 'N/A'}},
          {ATM: {decimal: '', percent: 'N/A'}},
          {BARD1: {decimal: '', percent: 'N/A'}},
          {RAD51D: {decimal: '', percent: 'N/A'}},
          {RAD51C: {decimal: '', percent: 'N/A'}},
          {BRIP1: {decimal: '', percent: 'N/A'}}
        ]
      },
      claus: {
        breast: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: []
      },
      gail: {
        breast: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: []
      },
      tyrer_cuzick: {
        breast: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        mutation_probabilities: [
          {'no mutation': {decimal: '', percent: 'N/A'}},
          {BRCA1: {decimal: '', percent: 'N/A'}},
          {BRCA2: {decimal: '', percent: 'N/A'}}
        ]
      },
      brcapro: {
        breast: {fiveYear: 'N/A', lifetime: 'N/A'},
        ovarian: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        mutation_probabilities: [
          {'no mutation': {decimal: '', percent: 'N/A'}},
          {BRCA1: {decimal: '', percent: 'N/A'}},
          {BRCA2: {decimal: '', percent: 'N/A'}}
        ]
      },
      mmrpro: {
        colon: {fiveYear: 'N/A', lifetime: 'N/A'},
        uterine_endometrial: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        mutation_probabilities: [
          {"no mutation": {decimal: "", percent: "N/A"}},
          {MLH1: {decimal: "", percent: "N/A"}},
          {MSH2: {decimal: "", percent: "N/A"}},
          {MSH6: {decimal: "", percent: "N/A"}}
        ]
      },
      pancpro: {
        pancreatic: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        mutation_probabilities: [
          {"no mutation": {decimal: "", percent: "N/A"}},
          {pancreaticGene: {decimal: "", percent: "N/A"}}
        ]
      },
      melapro: {
        melanoma: {fiveYear: 'N/A', lifetime: 'N/A'},
        cancer_risks: [],
        mutation_probabilities: [
          {"no mutation": {decimal: "", percent: "N/A"}},
          {P16: {decimal: "", percent: "N/A"}}
        ]
      },
      qrisk3: {
        cardiovascular: {tenYear: 'N/A'},
        cancer_risks: []
      },
    },
    twins: {},
    risk_criteria: [],
    hidden_disease_colors: [],
    family_pedigree_notes: ''
  }

import React, { useEffect, useState, useRef } from "react";


interface PanelProps {
    onCancel: () => void;
    isOpen: boolean;
    title?: string;
    message?: string;
    onOk: () => void;
}



// React functional component with TypeScript
const ModalConfirmPanelChange: React.FC<PanelProps> = ({onCancel, isOpen, title, message, onOk }) =>{

  return(
    <div
        onClick={onCancel}
        style={{display:"block"}}
        className="modal fade in bangarang"
        role="dialog"
    >
      <div
        onClick={e => e.stopPropagation()}
        className="modal-dialog modal-sm"
        role="document"
      >
          <div className="modal-content">
            <div className="modal-header">
              <button
                onClick={onCancel}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa fa-close" />
              </button>
              <h4 className="modal-title text-white text-capitalize">
                {title ? title : "Delete Details"}
              </h4>
            </div>
            <div className="modal-body">
              <div className="modal-confirm">
                <p>
                  {message}
                </p>
                <br />
                  <div className="buttons">
                    <button
                      style={{paddingLeft: '0px', paddingRight: '0px'}}
                      onClick={onCancel}
                      className="btn btn-light btn-sm"
                      aria-label="Close"
                    >
                        <label>Cancel</label>
                    </button>
                    <button
                      style={{paddingLeft: '0px', paddingRight: '0px'}}
                      onClick={onOk}
                      className="btn btn-purple btn-sm"
                    >
                        <label>Continue</label>
                    </button>
                  </div>
                </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default ModalConfirmPanelChange;

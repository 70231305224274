import React, { Component, useState } from 'react';
import disease_api from "../../api/disease-api";


interface AccountCustomizationProps extends React.PropsWithChildren<{}> {
  org_id: string;
}

const AccountCustomizations: React.FC<AccountCustomizationProps> = ({org_id}) =>{
    const [diseaseFile, setDiseaseFile] = useState({})
    const [diseaseFileName, setDiseaseFileName] = useState("");

    const [samlFileName, setSamlFileName] = useState("");
    const [samlCert, setSamlCert] = useState("");

    const [successMessage, setSuccessMessage] = useState(""); 

    const handleDiseseListChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        const selectedFile = event.target.files?.[0]; // Get the selected file
        if (!selectedFile) {
            console.error("No file selected");
            return;
        }

        setDiseaseFileName(selectedFile.name); // Update state with file namz
        const reader = new FileReader();
        reader.onload = (loadEvent) => {
            const csvContent = loadEvent.target?.result as string;
            const jsonData = parseCSV(csvContent);
            setDiseaseFile(jsonData);
        };
    
        reader.readAsText(selectedFile);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0]; 
      if (selectedFile) {
        setSamlFileName(selectedFile.name);
        const reader = new FileReader();
        reader.onload = () => {
            const fileContent = reader.result as string;
        
            const certificate = extractCert(fileContent); 
            setSamlCert(certificate); 
        };
    
        reader.readAsText(selectedFile);
      }
    };

    const parseCSV = (csvContent: string) => {
        const lines = csvContent.trim().split("\n"); 
        const headers = lines[0].split(",").map(header => header.trim());
    
        return lines.slice(1).map(line => {
            const values = line.split(",").map(value => value.trim()); // Trim each value
            const entry: Record<string, string> = {};
    
            headers.forEach((header, index) => {
                entry[header] = values[index] || ""; // Assign value or empty string if missing
            });
    
            return entry;
        });
    };
    
    const extractCert = (certString: any) => {
        const cleanCert = certString.trim().replace(/\r\n/g, '\n');  // Ensure consistent line breaks

        // Regex to match the certificate
        const regex = /-----BEGIN CERTIFICATE-----\s*([\s\S]*?)\s*-----END CERTIFICATE-----/;
      
        // Match the certificate content
        const match = cleanCert.match(regex);
        console.log('match', match)
        if (match) {
          return match[1].trim();  // Return the certificate content without BEGIN/END markers
        } else {
          throw new Error('Invalid certificate format');
        }
    };

    const handleDiseseListSubmit = async() => {
      try{
        
        let payload = {
            org_id: org_id,
            custom_disease_list: diseaseFile
          }
          
        await disease_api.upload_disease_file(payload);

      }catch(err){
            console.log(err);
      }finally{
        setSuccessMessage('Disease list uploaded successfully');
        setTimeout(() => setSuccessMessage(''), 5000);
      }

    };

  console.log(org_id)
    return (
    <div className="custom-disease-upload-and-saml-configs">
      <div className="custom-disease-upload-container">
        <h3>Upload a Custom Disease List</h3>
        <p style={{color:'green'}}>
        {successMessage}
        </p>

        <div className="custom-disease-upload-row">
          <div className="custom-disease-upload-col">
            <span className="custom-disease-upload-control-fileupload">
              <label className="custom-disease-upload-file-label">
                <br />
                  <input
                    name="file"
                    id="custom-disease-upload-file"
                    type="file"
                    className="custom-disease-upload-file-input"
                    onChange={(event) => handleDiseseListChange(event)}
                  />
            </label>
            </span>
          </div>
        
          <div className="custom-disease-upload-col-submit">
            <button type="button" onClick={handleDiseseListSubmit} className="custom-disease-upload-submit-btn">
              Submit
            </button>
          </div>
        </div>
      </div>

      <div className="saml-configs-container">
        <h4>Saml Configs (this currently does not work)</h4>
        <label className="saml-configs-entity-idp-label">Entity IDP</label>
        <input id="saml-configs-entity-idp" type="text" className="saml-configs-input" />
        <label className="saml-configs-redirect-url-label">Redirect URL</label>
        <input id="saml-configs-redirect-url" type="text" className="saml-configs-input" />
        <label className="saml-configs-pkce-enabled-label">
          PKCE Enabled
          <input id="saml-configs-pkce-enabled" type="checkbox" className="saml-configs-checkbox" />
        </label>
        <br />
        <label className="saml-configs-certificate-label">Certificate</label>
        <span className="saml-configs-control-fileupload">
          <label htmlFor="file" className="saml-configs-file-label">
            {samlFileName ? "" : "Please choose a file on your computer."}
                <input
                  name="file"
                  id="saml-configs-file"
                  type="file"
                  className="saml-configs-file-input"
                  onChange={handleFileChange}
                />
            </label>
        </span>
      </div>
    </div>

    );
  }

export default AccountCustomizations;

import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import {Checkbox} from 'react-icheck';
import account_api from '../../../api/user-account-api';

class ModalRiskSettings extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this.props.riskSettings,
      competing_mortality: this.props.riskSettings.competing_mortality,
      boadicea_incidence_rates: 'USA',
      license: {
        boadicea: false,
        gail: false,
        claus: false,
        tyrer_cuzick: false,
        brcapro: false,
        mmrpro: false,
        pancpro: false,
        melapro: false,
        qrisk3: false
      }
    };

    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.changeBoadiceaIncidenceRate = this.changeBoadiceaIncidenceRate.bind(this);
  }

  async componentDidMount() {
    const org = await account_api.get_org_by_id(this.props.session.user.organization_id);

    // Remove next two lines when gail and claus can be disabled
    let license = org.license;
    if(license) {
      this.setState({license: license, boadicea_incidence_rates: license.boadicea_incidence_rates});
    }
  }

  onChangeCheckBox(name, checked) {
    this.setState({ [name]: checked });
  }

  onClickSave(event) {
    this.props.onClickSave(this.state);
    this.props.onClose();
  }

  changeBoadiceaIncidenceRate(item) {
    if(item) this.setState({ boadicea_incidence_rates: item.value });
  }

  render() {
    const boadicea_disabled = !this.state.license.boadicea;
    const tyrer_cuzick_disabled = !this.state.license.tyrer_cuzick;
    const gail_disabled = !this.state.license.gail;
    const claus_disabled = !this.state.license.claus;
    const brcapro_disabled = !this.state.license.brcapro;
    const mmrpro_disabled = !this.state.license.mmrpro;
    const pancpro_disabled = !this.state.license.pancpro;
    const melapro_disabled = !this.state.license.melapro;
    const qrisk3_disabled = !this.state.license.qrisk3;

    let standard_label = 'col-md-4 col-sm-4 control-label';
    let disabled_label = standard_label + ' grayout';

    const incidence_rate_choices = [
      { value: 'UK', label: 'UK'},
      { value: 'Australia', label: 'Australia'},
      { value: 'Canada', label: 'Canada'},
      { value: 'USA', label: 'USA'},
      { value: 'Denmark', label: 'Denmark'},
      { value: 'Finland', label: 'Finland'},
      { value: 'Iceland', label: 'Iceland'},
      { value: 'New-Zealand', label: 'New-Zealand'},
      { value: 'Norway', label: 'Norway'},
      { value: 'Spain', label: 'Spain'},
      { value: 'Sweden', label: 'Sweden'},
      { value: 'Other', label: 'Other'},
    ];

    let incidence_rate = '';
    for(var item of incidence_rate_choices) {
      if(this.state.boadicea_incidence_rates && this.state.boadicea_incidence_rates.toLowerCase() === item.label.toLowerCase()) {
        incidence_rate = item;
      }
    }

    return ReactDOM.createPortal(
      <div onClick={() => this.props.onClose()}
        style={{ display: 'block' }}
        className="modal fade in"
        role="dialog"
        aria-labelledby="risk-settings"
        id="risk-settings">

        <div onClick={(e) => e.stopPropagation()} className="modal-dialog" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <button onClick={() => this.props.onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <i className="fa fa-close"></i>
              </button>
              <h4 className="modal-title text-white text-capitalize">Risk Settings</h4>
            </div>

            <div className="modal-body">
              <form className="form-horizontal form-label-left form-custom">
                <p>Select the risk to run:</p>
                <div className="row">
                  <div className="col-md-1 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="boadicea"
                      name="boadicea"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.boadicea}
                      disabled={boadicea_disabled}
                    />
                  </div>
                  <label htmlFor="boadicea" className={!boadicea_disabled ? standard_label : disabled_label}>BOADICEA</label>

                  <div className="col-md-6">
                    <Select
                      value={incidence_rate}
                      name="boadicea_incidence_rates"
                      onChange={(item) => {
                        this.changeBoadiceaIncidenceRate(item);
                      }}
                      className='react-select-container'
                      classNamePrefix="react-select"
                      isClearable={true}
                      placeholder=""
                      options={incidence_rate_choices}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-1 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="claus"
                      name="claus"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.claus}
                      disabled={claus_disabled}
                    />
                  </div>
                  <label htmlFor="claus" className={!claus_disabled ? standard_label : disabled_label}>CLAUS</label>
                </div>

                <div className="row">
                  <div className="col-md-1 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="gail"
                      name="gail"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.gail}
                      disabled={gail_disabled}
                    />
                  </div>
                  <label htmlFor="gail" className={!gail_disabled ? standard_label : disabled_label}>GAIL</label>
                </div>

                <div className="row">
                  <div className="col-md-1 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="tyrer_cuzick"
                      name="tyrer_cuzick"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.tyrer_cuzick}
                      disabled={tyrer_cuzick_disabled}
                    />
                  </div>
                  <label htmlFor="tyrer_cuzick" className={!tyrer_cuzick_disabled ? standard_label : disabled_label}>TYRER-CUZICK</label>

                  {!tyrer_cuzick_disabled && (
                    <div>
                      <label htmlFor="competing_mortality" className={standard_label}>Competing Mortality</label>
                      <div className="col-md-2">
                        <div className="checkbox">
                          <label className="switch">
                            <input
                              name="competing_mortality"
                              id="competing_mortality"
                              onChange={() => this.setState({competing_mortality: !this.state.competing_mortality})}
                              checked={this.state.competing_mortality}
                              type="checkbox" />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-md-1 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="brcapro"
                      name="brcapro"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.brcapro}
                      disabled={brcapro_disabled}
                    />
                  </div>
                  <label htmlFor="brcapro" className={!brcapro_disabled ? standard_label : disabled_label}>BRCAPRO</label>
                </div>

                <div className="row">
                  <div className="col-md-1 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="mmrpro"
                      name="mmrpro"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.mmrpro}
                      disabled={mmrpro_disabled}
                    />
                  </div>
                  <label htmlFor="mmrpro" className={!mmrpro_disabled ? standard_label : disabled_label}>MMRpro</label>
                </div>

                <div className="row">
                  <div className="col-md-1 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="pancpro"
                      name="pancpro"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.pancpro}
                      disabled={pancpro_disabled}
                    />
                  </div>
                  <label htmlFor="pancpro" className={!pancpro_disabled ? standard_label : disabled_label}>PANCpro</label>
                </div>

                <div className="row">
                  <div className="col-md-1 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="melapro"
                      name="melapro"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.melapro}
                      disabled={melapro_disabled}
                    />
                  </div>
                  <label htmlFor="melapro" className={!melapro_disabled ? standard_label : disabled_label}>MELApro</label>
                </div>

                <div className="row">
                  <div className="col-md-1 radio-inline famhis-radio-inline">
                    <Checkbox
                      id="qrisk3"
                      name="qrisk3"
                      onChange={(e, checked) => {
                        this.onChangeCheckBox(e.target.name, checked)
                      }}
                      checkboxClass="icheckbox_flat-green"
                      increaseArea="10%"
                      checked={this.state.qrisk3}
                      disabled={qrisk3_disabled}
                    />
                  </div>
                  <label htmlFor="qrisk3" className={!qrisk3_disabled ? standard_label : disabled_label}>QRISK3</label>
                </div>

              </form>
            </div>

            { ( boadicea_disabled || gail_disabled || claus_disabled || tyrer_cuzick_disabled || brcapro_disabled || mmrpro_disabled || pancpro_disabled || melapro_disabled || qrisk3_disabled) &&
              <div>
                <p className="row-centered">Disabled risk models are available but not currently activated for your account.<br/>Contact FamGenix if you would like to add them.</p>
              </div>
            }

            <div className="modal-footer">
              <button
                onClick={() => this.props.onClose()}
                type="button" className="btn btn-light-outline no-margin-right" data-dismiss="modal">Cancel</button>
              <button
                onClick={this.onClickSave}
                type="button" className="btn btn-dark">Save</button>
            </div>
          </div>
        </div>
      </div>,
      document.body
    );
  }

}

export default ModalRiskSettings;

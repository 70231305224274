import React, { Component } from "react";
import IntlTelInput from 'react-intl-tel-input';
import { connect } from 'react-redux';
import * as countryCodes from '../../../../patient-profile/patient-data-tabs/dial-code';
import 'react-intl-tel-input/dist/main.css';

import LabelField from './label-field';

class PhoneField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone_number: '',
      phone_valid: true,
    };

    this.onPhoneSelect = this.onPhoneSelect.bind(this);
    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onPhoneBlur = this.onPhoneBlur.bind(this);
  }

  componentDidMount() {
    if (this.props.answer) {
      this.setState({ phone_number: this.props.answer.answer.slice(2)});
    }
  }

  onPhoneSelect() {
    this.setState({phone_valid: true, phone_number: ''});
  }

  onPhoneChange(phone_valid, phone, c) {
    this.setState({phone_valid: phone_valid, phone_number: phone});
  }

  onPhoneBlur(phone_valid, phone, c) {
    if (phone_valid) {
      const payload = {
        field_type: this.props.masterQuestion.type,
        master_question_id: this.props.masterQuestion.id,
        master_question_choice_id: null,
        answer: c.iso2 + phone
      };

      this.props.saveCompletedSurveyAnswer(payload);
    }
  }

  render() {
    let column_one = null;
    let column_two = null;

    let country_dial_code = " ";
    if (
      this.props.proband.phone_number &&
      this.props.proband.dial_code &&
      country_dial_code !== this.props.proband.dial_code
    ) {
      let number = this.props.proband.phone_number.replace(/[()]/g, "");
      let area_code = number.substring(0, 3);
      for (let code of countryCodes.allCountries) {
        if (this.props.proband.dial_code === code[2]) {
          country_dial_code =
          this.props.proband.dial_code === "1" &&
            countryCodes.canadaAreaCodes.includes(area_code)
              ? "ca"
              : code[1];
          break;
        }
      }
    } else if (this.props.dial_code) {
      country_dial_code = this.props.dial_code;
    }

    const field = (
      <IntlTelInput
        name={this.props.masterQuestion.question_uuid}
        id={this.props.masterQuestion.question_uuid}
        value={this.state.phone_number}
        format={true}
        inputClassName="phone-input survey-phone-field-input"
        defaultCountry={country_dial_code}
        onSelectFlag={() => {
          this.onPhoneSelect();
        }}
        onPhoneNumberChange={(phone_valid, phone, c) => {
          this.onPhoneChange(phone_valid, phone, c);
        }}
        onPhoneNumberBlur={(phone_valid, phone, c) => {
          this.onPhoneBlur(phone_valid, phone, c);
        }}
      />
    );

    if (this.props.masterQuestion.question_label) {

      column_one = (
        <LabelField
          masterQuestion={this.props.masterQuestion}
        />
      );

    }

    column_two = field;

    return (
      <div className="form-group">
        <div className="row">

          <div className="col-md-6">
            {column_one}
          </div>

          <div className="col-md-6">
            {column_two}
          </div>

        </div>
      </div>
    );

  }
}

const redux_state = (state) => ({
  dial_code: state.session.user.dial_code,
});

export default connect(redux_state, null)(PhoneField);

